@keyframes blink {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink-border {
    0%, 100% {
        box-shadow: 0 0 0 1px rgba(255, 0, 0, 1);
    }
    50% {
        box-shadow: 0 0 0 1px rgba(255, 0, 0, 0);
    }
}

[data-animation-helper = "transition"] {
    transition: all var(--default-animation-duration);
}

[data-animation-helper = "scale-hover"] {
    transition: all var(--default-animation-duration);
}

[data-animation-helper = "scale-hover"]:hover {
    transform: scale(1.02);
}

[data-animation-helper = "opacity-hover-01"] {
    transition: all var(--default-animation-duration);
    opacity: 0.1;
}

[data-animation-helper = "opacity-hover-01"]:hover {
    opacity: 1;
}

[data-animation-helper = "opacity-hover-05"] {
    transition: all var(--default-animation-duration);
    opacity: 0.5;
}

[data-animation-helper = "opacity-hover-05"]:hover {
    opacity: 1;
}

[data-bc = "default"] {
    border-color: var(--color-surface-2) !important;
}

[data-bc = "primary"] {
    border-color: var(--color-primary) !important;
}

[data-bc = "primary-75"] {
    background-color: var(--color-primary-75) !important;
}

[data-bc = "primary-50"] {
    background-color: var(--color-primary-50) !important;
}

[data-bc = "primary-25"] {
    background-color: var(--color-primary-25) !important;
}

[data-bg = "primary"] {
    background-color: var(--color-primary) !important;
}

[data-bg = "primary-75"] {
    background-color: var(--color-primary-75) !important;
}

[data-bg = "primary-50"] {
    background-color: var(--color-primary-50) !important;
}

[data-bg = "primary-25"] {
    background-color: var(--color-primary-25) !important;
}

[data-bg = "secondary"] {
    background-color: var(--color-secondary) !important;
}

[data-bg = "tertiary"] {
    background-color: var(--color-tertiary) !important;
}

[data-bg = "surface-0"] {
    background-color: var(--color-surface-0) !important;
}

[data-bg = "surface-070"] {
    background-color: var(--color-surface-070) !important;
}

[data-bg = "surface-1"] {
    background-color: var(--color-surface-1) !important;
}

[data-bg = "surface-2"] {
    background-color: var(--color-surface-2) !important;
}

[data-bg = "surface-3"] {
    background-color: var(--color-surface-3) !important;
}

[data-bg = "surface-4"] {
    background-color: var(--color-surface-4) !important;
}

[data-bg = "transparent"] {
    background-color: transparent !important;
}

[data-bg = "linear-0-1"] {
    background: linear-gradient(var(--color-surface-0), var(--color-surface-1));
}

[data-bg = "linear-1-2"] {
    background: linear-gradient(var(--color-surface-1), var(--color-surface-2));
}

[data-blink = "true"] {
    border-style: solid !important;
    border-color: var(--color-primary) !important;
    border-width: 1px !important;
    border-radius: var(--default-border-radius);
    animation: blink 1s 1;
}

[data-blink-border-loop = "true"] {
    border-style: solid !important;
    border-width: 1px !important;
    border-radius: var(--default-border-radius);
    animation: blink-border infinite 2500ms;
}

[data-boxs = "surface-2"] {
    box-shadow: 0 0 10px var(--color-surface-2) !important;
}

[data-brdr = "default"] {
    border: var(--default-border) var(--color-surface-2) !important;
}

[data-brdr = "default-dashed"] {
    border-style: dashed;
    border-width: calc(var(--default-border-width) * 2);
    border-color: var(--color-surface-2);
    border-radius: var(--default-border-radius);
}

[data-brdr = "default-bottom"] {
    border-bottom: var(--default-border) var(--color-surface-2) !important;
}

[data-brdr = "default-top"] {
    border-top: var(--default-border) var(--color-surface-2) !important;
}

[data-brdr = "default-left"] {
    border-left: var(--default-border) var(--color-surface-2) !important;
}

[data-brdr = "default-right"] {
    border-right: var(--default-border) var(--color-surface-2) !important;
}

[data-brdr = "default-sides"] {
    border-left: var(--default-border) var(--color-surface-2) !important;
    border-right: var(--default-border) var(--color-surface-2) !important;
}

[data-brdr = "default-rounded"] {
    border: var(--default-border) var(--color-surface-2) !important;
    border-radius: var(--default-border-radius) !important;
}

[data-brdr = "primary-75-rounded"] {
    border: var(--default-border) var(--color-primary-75) !important;
    border-radius: var(--default-border-radius) !important;
}

[data-brdr = "primary-50-rounded"] {
    border: var(--default-border) var(--color-primary-50) !important;
    border-radius: var(--default-border-radius) !important;
}

[data-brdr = "primary-25-rounded"] {
    border: var(--default-border) var(--color-primary-25) !important;
    border-radius: var(--default-border-radius) !important;
}

[data-brdr = "none-bottom"] {
    border-bottom: none !important;
}

[data-brdr = "none"] {
    border: none !important;
}

[data-brdr = "none-rounded"] {
    border: none !important;
    border-radius: var(--default-border-radius) !important;
}

[data-c = "primary"] {
    color: var(--color-primary) !important;
}

[data-c = "secondary"] {
    color: var(--color-secondary) !important;
}

[data-c = "tertiary"] {
    color: var(--color-tertiary) !important;
}

[data-c = "success"] {
    color: var(--color-success) !important;
}

[data-c = "error"] {
    color: var(--color-error) !important;
}

[data-c = "approved"] {
    color: var(--color-updated) !important;
}

[data-c = "sent"] {
    color: var(--color-sent) !important;
}

[data-c = "rejected"] {
    color: var(--color-rejected) !important;
}

[data-c = "text"] {
    color: var(--color-text) !important;
}

[data-c = "surface-0"] {
    color: var(--color-surface-0) !important;
}

[data-c = "surface-1"] {
    color: var(--color-surface-1) !important;
}

[data-c = "surface-2"] {
    color: var(--color-surface-2) !important;
}

[data-c = "surface-3"] {
    color: var(--color-surface-3) !important;
}

[data-c = "surface-4"] {
    color: var(--color-surface-4) !important;
}

[data-c = "chart-1"] {
    color: hsl(var(--chart-1)) !important;
}

[data-c = "chart-2"] {
    color: hsl(var(--chart-2)) !important;
}

[data-c = "chart-3"] {
    color: hsl(var(--chart-3)) !important;
}

[data-c = "chart-4"] {
    color: hsl(var(--chart-4)) !important;
}

[data-c = "chart-5"] {
    color: hsl(var(--chart-5)) !important;
}

[data-c = "chart-6"] {
    color: hsl(var(--chart-6)) !important;
}

[data-c = "chart-7"] {
    color: hsl(var(--chart-7)) !important;
}

[data-c = "chart-8"] {
    color: hsl(var(--chart-8)) !important;
}

[data-c = "chart-9"] {
    color: hsl(var(--chart-9)) !important;
}

[data-c = "chart-10"] {
    color: hsl(var(--chart-10)) !important;
}

[data-crsr = "pointer"] {
    cursor: pointer !important;
}

[data-ff = "consolas"] {
    font-family: "monospace", "Consolas" !important;
}

[data-fs = "xxs"] {
    font-size: var(--font-size-xxs) !important;
}

[data-fs = "xs"] {
    font-size: var(--font-size-xs) !important;
}

[data-fs = "sm"] {
    font-size: var(--font-size-sm) !important;
}

[data-fs = "md"] {
    font-size: var(--font-size-md) !important;
}

[data-fs = "lg"] {
    font-size: var(--font-size-lg) !important;
}

[data-fs = "xl"] {
    font-size: var(--font-size-xl) !important;
}

[data-fs = "xxl"] {
    font-size: var(--font-size-xxl) !important;
}

[data-h = "xs"] {
    height: var(--input-height-xs) !important;
}

[data-hbg = "primary"]:hover {
    background-color: var(--color-primary-hover) !important;
}

[data-hbg = "surface-0"]:hover {
    background-color: var(--color-surface-0) !important;
}

[data-hbg = "surface-1"]:hover {
    background-color: var(--color-surface-1) !important;
}

[data-hbg = "surface-2"]:hover {
    background-color: var(--color-surface-2) !important;
}

[data-nested = "0"] {
    margin-top: 3% !important;
}

[data-nested = "1"] {
    margin-top: 5% !important;
}

[data-nested = "2"] {
    margin-top: 7% !important;
}

[data-nested = "3"] {
    margin-top: 9% !important;
}

[data-nested = "4"] {
    margin-top: 11% !important;
}

[data-ovf = "hidden"] {
    overflow: hidden !important;
}

[data-ovf = "scroll"] {
    overflow: scroll !important;
}

[data-ovf = "visible"] {
    overflow: visible !important;
}

[data-ovf = "auto"] {
    overflow: auto !important;
}

[data-ovf = "scrollx"] {
    overflow-x: auto !important;
}

[data-ovf = "scrolly"] {
    overflow-y: auto !important;
}

[data-p = "0"] {
    padding: 0 !important;
}

[data-p = "4"] {
    padding: 4px !important;
}

[data-p = "8"] {
    padding: 8px !important;
}

[data-pos = "absolute"] {
    position: absolute !important;
}

[data-pos = "relative"] {
    position: relative !important;
}

[data-tw = "no-wrap"] {
    text-wrap: nowrap;
}

[data-util = "inherit-fh"] > * {
    height: 100% !important;
}
