[style-variant = "unstyled"] {
    background-color: transparent;
    border: none;
    border-radius: 0;
    overflow: hidden;
}

[style-variant = "styled"] {
    background-color: var(--color-surface-1);
    border: var(--color-surface-2) var(--default-border);
    border-radius: var(--default-border-radius);
    overflow: hidden;
}

.thumb{
    z-index: 10;
}